.secWork
{
  background: #fff;
  padding: 100px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workBx
{
 margin-top: 100px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  max-width: 100%;
  margin-top: 40px;
}
.workBx .brand
{
  position: relative;
  background: #4978ff;
  min-height: 300px;
  width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workBx .brand:hover
{
  background: #000;
}
.workBx .brand a
{
  text-decoration: none;
  color: rgba(255,255,255,.4);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}
.workBx .brand:hover a
{
  text-decoration: none;
  color: #fff;
}

.WorkTitle
{
  color: #000;
  font-size: 40px;
}
.WorkContent
{
  color: #000;
  font-size: 30px;
}
.statsBox h2
{
  color: #fff;
  font-size: 36px;
}