.sec
{
  background: #fff;
  padding: 100px;
  display: flex;
  margin-top:-20% ;
  justify-content: center;
  align-items: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  
  
}

.sec .content
{
  position: relative;
  text-align: center;
  width: 100%;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.mxw800p
{
  max-width: 800px;
  margin: 0 auto;
}
.SectionTitle
{
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}
.SectionContent
{
  position: relative;
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.square {
  width: 200px;
  height: 200px;
  background-color: #fffdfd;
  border: 2px solid black;
}
.btn
{
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid #000;
  text-decoration: none;
  background: #000;
  color: #fff;
}
@media (max-width: 768px) {
.sec{
background: #fff;
padding: 100px;
min-height: 100vh;
display: flex;
margin-top: -20%;
justify-content: center;
align-items: center;
font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
}