.secContact
{
  background: #000000;
  padding: 100px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact
{
  background: #000;
}
.FooterTitle
{
  color: #eae9e9;
  font-size: 40px;
}
.FooterContent
{
  color: #eae9e9;
  font-size: 30px;
}
.conactForm
{
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
}
.conactForm form
{
  width: 100%;
}
.conactForm .row100
{
  display: flex;
  width: 100%;
}
.conactForm .row100 .inputBx50
{
  width: 50%;
  margin: 0 20px;
}
.conactForm .row100 .inputBx100
{
  width: 100%;
  margin: 0 20px;
}
.conactForm .row100 input,
.conactForm .row100 textarea
{
  position: relative;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  background: transparent;
  width: 100%;
  padding: 10px 0;
  outline: none;
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
  resize: none;
}
.conactForm .row100 textarea
{
  height: 100px;
}
.conactForm .row100 input::placeholder,
.conactForm .row100 textarea::placeholder
{
  color: rgba(255,255,255,.5);
}
.conactForm .row100 input[type="submit"]
{
  background: #fff;
  color: #000;
  max-width: 100px;
  margin: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  font-weight: 500;
}
.sci
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0; 
}
.sci ul
{
  display: flex;
}
.sci ul li
{
  list-style: none;
}
.sci ul li a
{
  text-decoration: none;
  display: inline-block;
  margin: 0 30px;
}
.sci ul li a img
{
  filter: invert(1);
  max-width: 40px;
}
.sci ul li a:hover img
{
  opacity: 0.5;
}
.copyright a
{
  color: #fff;
}
.toggle
{
  display: none;
}
