.feedback {
  background-color : #000000;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: #e8e8e8;
  position:relative
}

.mybutton {
  position: fixed;
  top: 10px;
  left: 10px;
}

.sidebar{
  width:10%;
  color:black;
}


.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.814);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 1000;
}

.sidebar.open {
  transform: translateX(0);
}

