.sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    background-color: #333;
    transition: left 0.3s ease;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: #9d0000;
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
  }
  
  .content {
   
    color: #000000;
  }
  