.secTeams
{
  background: #fff;
  padding: 100px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top:5px;
}

.teamBx
{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 45px;
}
.teamBx .member
{
  position: relative;
  background: #f5f5f5;
  min-height: 300px;
  width: 250px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teamBx .member .imgBx
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teamBx .member .imgBx img
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teamBx .member .details
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  transition: 0.2s;
}
.teamBx .member:hover .details
{
  opacity: 1;
}
.teamBx .member .details h2
{
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
}
.teamBx .member .details h2 span
{
  display: block;
  font-size: 12px;
  font-weight: 300;
}

.TeamsTitle
{
  color: #000;
  font-size: 40px;
}
.TeamsContent
{
  color: #000;
  font-size: 30px;
}