


.sidebarUl {
        padding: 10px;
  }
  
  .sidebarUl {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Displays the list items vertically */
    list-style-type: none; /* Removes the bullet points */
    padding: 0;
  }
  
  .sidebarLI {
    margin-bottom: 10px;
    margin: auto;
    margin-bottom: 10px;
  }
  
  .sidebarLI a {
    text-decoration: none;
    color: #fffbfb;
  }
.ButtonClose{
    background-color: transparent;
    color:white;
    float:right;
}
  