header
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  padding: 40px 100px;
  z-index: 100000;
}
header.sticky
{
  padding: 5px 100px;
  background: #fff;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
header .logo
{
  position: relative;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.6s;
}
header ul
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
header ul li
{
  position: relative;
  list-style: none;
}
header ul li a
{
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 300;
  transition: 0.6s;
}
  .show {
    display: block;
  }
  
  .hide {
    display: none;
  }
  .scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    /* Additional styling */
  }

  .logo{
    font-size: 2em;
  }
  
  .scroll-button.show {
    display: block;
  }

 
  .permanent-button {
    position: fixed;
    top: 10px; /* Adjust this value to set the desired distance from the top */
    left: 10px; /* Adjust this value to set the desired distance from the left */
  }
  
 
.hidden{
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 0px solid rgba(0, 0, 0, .1);
    position: fixed;
    top: -80px;
    transition: 0.3s linear;

}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  color: #fff;
  position:relative

}



.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .logo {
    font-size: 20px;
    float: left;
  }
 
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .mobile-menu-toggle {
    display: block;
    
    
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #000000;
    padding: 20px;

  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin-bottom: 10px;
  }
}
