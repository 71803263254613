.about 
{
  min-height: 121vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content
{
  position: relative;
  text-align: center;
  width: 100%;
}
.mxw800p
{
  max-width: 800px;
  margin: 0 auto;
}
.ServiceTitle
{
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
}
.ServiceContent
{
  position: relative;
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.services
{

  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 60px;
  max-width: 100%;
  align-items: center;


}
.services .box
{
  width: 350px;
  margin: 0 auto;
  background: #fff;
  margin-left: -16px;
  width: 269px;

  box-shadow: 0 15px 30px rgb(0, 0, 0);
}
.sec .content {
    position: relative;
    text-align: center;
    width: 100%;
}
.services
{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  max-width: 100%;
  margin-top: 50px;
  padding-bottom: 60px;
}
.services .box
{
  width: 100%;
  margin: 0 auto;
  background: #fff;
  height: 300px;
  box-shadow: 0 15px 30px rgb(0, 0, 0);
}
.services .box .boxBoxContain
{
  margin: 0 auto;
  width: 300px;
  height:200px;

}
.services .box .iconBx
{
  margin-top: 25px;
}
.services .box .iconBx img
{
  max-width: 70px;
  margin-bottom: 15px;
}
.services .box .boxTitle
{
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}
.services .box .boxContent
{
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.stats
{
  background: #000;
  height:fit-content;
}
.stats h3,
.stats p
{
  color: #fff;
}
.statsBox
{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 0px;
  margin-top: 40px;
}
.statsTitle
{
  color: #fff;
  font-size: 40px;
}
.statsContent
{
  color: #fff;
  font-size: 30px;
}
.statsBox h2
{
  color: #fff;
  font-size: 36px;
}
.statsBox h4
{
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}